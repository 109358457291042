@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.App {
  text-align: center;
  font-size: 12px;
}

body {
  font-size: 13px
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table thead th:first-of-type{
  border-top-left-radius: 10px; 
}

.table thead th:last-of-type{
  border-top-right-radius: 10px; 
}

div.myDiv {
  float: right;
}
div.myDiv:after {
  /* clear floats is a good thing */
  content: '';
  display: block;
  clear: both;
}
div.myDiv input {
  float: right;
  width: 150px;
  margin-bottom: 3;
  height: 26px
}

.main-card {
  padding: 0px;
}

.main-card .card-header {
  background-color: #515e73;
  color: white
}

.secondary-card {
  padding: 0px;
  border: 1px solid;
  border-color: #b5e1f7;
  box-shadow: 0 1px rgba(0, 0, 0, 0.05);
}

.secondary-card .card-header {
  background-color: #c6e8f8;
  color: #31708f;
  font-size: small;
  font-weight: bold;
}

.emp-view {
  margin-top: 40px;
}

.required::after {
  content: ' *';
  color: red;
}

.form-group {
  margin-top: 5px;
  margin-bottom: 0px;
}

.form-label {
  padding: 0px;
  margin-top: 1px;
  margin-bottom: 3px;
}

.table.row {
  padding: 0px;
}

.badge {
  padding: 5px 10px;
  font-weight: 500;
  font-size: smaller;
}

.card-icon {
  color: white
}

.secondary-badge {
  padding: 5px;
}

.select-css {
	display: block;
	font-size: 13px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
  margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: #888;
}
.select-css:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.select-css option {
	font-weight:normal;
}

.emp-view-list {
  padding-top: 17px
}

.emp-view-list ul {
  list-style: none;
}

.emp-view-list ul li span {
  font-weight: bold;
}

.emp-view-list ul li:nth-child(1) span {
  padding-right: 10px;
}

.emp-view-list ul li:nth-child(2) span {
  padding-right: 11px;
}

.emp-view-list ul li:nth-child(3) span {
  padding-right: 32px;
}

.emp-view-list ul li:nth-child(4) span {
  padding-right: 58px;
}

#emp-view-personal .form-label {
  padding-left: 7.6rem;
}

#emp-view-personal .form-group:nth-child(1) span {
  padding-left: 6.26em;
}

#emp-view-personal .form-group:nth-child(2) span {
  padding-left: 8.9em;
}

#emp-view-personal .form-group:nth-child(3) span {
  padding-left: 5.8em;
}

#emp-view-personal .form-group:nth-child(4) span {
  padding-left: 5.7em;
}

#emp-view-contact .form-label {
  padding-left: 100px;
}

#emp-view-contact .form-group:nth-child(1) span {
  padding-left: 6.5em;
}

#emp-view-contact .form-group:nth-child(2) span {
  padding-left: 6.8em;
}

#emp-view-contact .form-group:nth-child(3) span {
  padding-left: 7.25em;
}

#emp-view-contact .form-group:nth-child(4) span {
  padding-left: 4em;
}

#emp-view-bank .form-label {
  padding-left: 7.6rem;
}

#emp-view-bank .form-group:nth-child(1) span {
  padding-left: 7em;
}

#emp-view-bank .form-group:nth-child(2) span {
  padding-left: 5.45em;
}

#emp-view-bank .form-group:nth-child(3) span {
  padding-left: 9em;
}

#emp-view-bank .form-group:nth-child(4) span {
  padding-left: 9.9em;
}



#emp-view-personal-dashboard .form-label {
  padding-left: 2rem;
}

#emp-view-personal-dashboard .form-group:nth-child(1) span {
  padding-left: 6.26em;
}

#emp-view-personal-dashboard .form-group:nth-child(2) span {
  padding-left: 8.9em;
}

#emp-view-personal-dashboard .form-group:nth-child(3) span {
  padding-left: 5.8em;
}

#emp-view-personal-dashboard .form-group:nth-child(4) span {
  padding-left: 5.7em;
}

#emp-view-contact-dashboard .form-label {
  padding-left: 2rem;
}

#emp-view-contact-dashboard .form-group:nth-child(1) span {
  padding-left: 6.5em;
}

#emp-view-contact-dashboard .form-group:nth-child(2) span {
  padding-left: 6.8em;
}

#emp-view-contact-dashboard .form-group:nth-child(3) span {
  padding-left: 7.25em;
}

#emp-view-contact-dashboard .form-group:nth-child(4) span {
  padding-left: 4em;
}

#emp-view-bank-dashboard .form-label {
  padding-left: 2rem;
}

#emp-view-bank-dashboard .form-group:nth-child(1) span {
  padding-left: 7em;
}

#emp-view-bank-dashboard .form-group:nth-child(2) span {
  padding-left: 5.45em;
}

#emp-view-bank-dashboard .form-group:nth-child(3) span {
  padding-left: 9em;
}

#emp-view-bank-dashboard .form-group:nth-child(4) span {
  padding-left: 9.9em;
}








#sal-view-details .form-label {
  padding-left: 7.6rem;
}

#sal-view-details .form-group:nth-child(1) span {
  padding-left: 6em;
}

#sal-view-details .form-group:nth-child(2) span {
  padding-left: 8.9em;
}


#sal-view-allowances .form-label {
  padding-left: 7.6rem;
}

#sal-view-allowances .form-group:nth-child(1) span {
  padding-left: 6em;
}

#sal-view-allowances .form-group:nth-child(2) span {
  padding-left: 7.7em;
}

#sal-view-allowances .form-group:nth-child(3) span {
  padding-left: 8.1em;
}

#sal-view-allowances .form-group:nth-child(4) span {
  padding-left: 9.45em;
}

#sal-view-allowances .form-group:nth-child(5) span {
  padding-left: 6.6em;
}

#sal-view-allowances .form-group:nth-child(6) span {
  padding-left: 8.75em;
}

#sal-view-allowances .form-group:nth-child(7) span {
  padding-left: 9.08em;
}

#sal-view-deductions .form-label {
  padding-left: 7.6rem;
}

#sal-view-deductions .form-group:nth-child(1) span {
  padding-left: 7em;
}

#sal-view-deductions .form-group:nth-child(2) span {
  padding-left: 5.9em;
}

#sal-view-deductions .form-group:nth-child(3) span {
  padding-left: 6.21em;
}

#sal-view-total .form-label {
  padding-left: 7.6rem;
}

#sal-view-total .form-group:nth-child(1) span {
  padding-left: 10.5em;
}

#sal-view-total .form-group:nth-child(2) span {
  padding-left: 8.86em;
}

#sal-view-total .form-group:nth-child(3) span {
  padding-left: 11.47em;
}




time.icon
{
  font-size: 0.55em; /* change icon size */
  display: block;
  position: relative;
  width: 7em;
  height: 7em;
  background-color: #fff;
  margin: 0em auto;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}

time.icon *
{
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time.icon strong
{
  position: absolute;
  top: 0;
  padding: 0.4em 0;
  color: #fff;
  background-color: #fd9f1b;
  border-bottom: 1px dashed #f37302;
  box-shadow: 0 2px 0 #fd9f1b;
}

time.icon em
{
  position: absolute;
  bottom: 0.3em;
  color: #fd9f1b;
}

time.icon span
{
  width: 100%;
  font-size: 2.8em;
  letter-spacing: -0.05em;
  padding-top: 0.8em;
  color: #2f2f2f;
}